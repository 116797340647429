$color-white: #ffffff;
$color-black: #000000;
$hero-section-background-light: #f6f5ff;
$primary-color-active: #0e940c;
$primary-color-hover: #0c5e0a;
$primary-color-light: #05ff00;

// Navbar
$light-navbar-background: #f6f5ff;

// Footer
$subscribe-input-background: #464141;
$footer-section-background: #1f1f22;
$footer-copywrite-area-background: #121213;

// Card
$tag-background-light: #94f39d;
$card-btn-background-active: #0e940c;
$card-btn-background-hover: #0f690e;

// Social Media Colors
$facebook-color: #1877f2;
$instagram-color: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
$linkedin-color: #295c77;
$youtube-color: #FF0000;
$twitter-color: #00acee;
$github-color: #1f1f22;
.event-primary-btn {
  background: $primary-color-active;
  color: $color-white;
  border-radius: 7px;
  font-family: "Product Sans Regular";
  font-size: 18px;
  width: 230px;
}

.event-primary-btn:hover {
  background: $primary-color-hover;
  color: $color-white;
}

.event-primary-btn:focus, .event-primary-btn:active {
  background: $primary-color-active;
  color: $color-white;
  outline: none !important;
  box-shadow: none;
}

.event-youtube-btn {
  background: $primary-color-active;
  color: $color-white;
  border-radius: 7px;
  font-family: "Product Sans Regular";
  font-size: 18px;
  width: 175px;
  margin-left: 10px;
}

.event-youtube-btn:hover {
  background: $primary-color-hover;
  color: $color-white;
}

.event-youtube-btn:focus, .event-youtube-btn:active {
  background: $primary-color-active;
  color: $color-white;
  outline: none !important;
  box-shadow: none;
}

.event-header-title {
  font-family: "Product Sans Bold";

  @include media("<=phone") {
    font-size: 50px;
  }

  @include media(">=phone", '<tablet') {
    font-size: 55px;
  }

  @include media('>=tablet', '<smalldesktop') {
    font-size: 55px;
  }

  @include media('>=smalldesktop', '<desktop') {
    font-size: 70px;
  }

  @include media(">=desktop", '<largedesktop') {
    font-size: 70px;
  }

  @include media('>=largedesktop') {
    font-size: 80px;
  }
}

.event-header-description {
  @include media("<=phone") {
    font-size: 18px;
  }

  @include media(">=phone", '<tablet') {
    font-size: 18px;
  }

  @include media('>=tablet', '<smalldesktop') {
    font-size: 18px;
  }

  @include media('>=smalldesktop', '<desktop') {
    font-size: 18px;
  }

  @include media(">=desktop", '<largedesktop') {
    font-size: 20px;
  }

  @include media('>=largedesktop') {
    font-size: 24px;
  }
}

.event-header-img {
  margin-left: 20px;
  margin-top: 10px;
  display: block;
  visibility: visible;

  @include media("<=phone") {
    width: 80%
  }

  @include media(">=phone", '<tablet') {
    width: 400px;
  }

  @include media('>=tablet', '<smalldesktop') {
    width: 350px;
  }

  @include media('>=smalldesktop', '<desktop') {
    width: 400px;
  }

  @include media(">=desktop", '<largedesktop') {
    width: 450px;
  }

  @include media('>=largedesktop') {
    width: 520px;
  }
}

@import "./breakpoints.scss";

/**
  Search Keywords - Home Page
  Header - "Home header"
  Who We Are - "Who We Are"
*/ 

.item-header {
  font-family: "Product Sans Medium";
  font-size: 40px;
}

.item-navigation {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.view-more-text {
  margin-right: 10px;
  font-family: "Roboto Medium";
}

.section-padding {
  margin-bottom: 10px;
}

// Home header styles - Start
.home-primary-btn {
  background: $primary-color-active;
  color: $color-white;
  border-radius: 7px;
  font-family: "Product Sans Regular";
  font-size: 18px;
  width: 100px;
}

.home-primary-btn:hover {
  background: $primary-color-hover;
  color: $color-white;
}

.home-primary-btn:focus, .home-primary-btn:active {
  background: $primary-color-active;
  color: $color-white;
  outline: none !important;
  box-shadow: none;
}

.home-header-title {
  font-family: "Product Sans Bold";

  @include media("<=phone") {
    font-size: 50px;
  }

  @include media(">=phone", '<tablet') {
    font-size: 55px;
  }

  @include media('>=tablet', '<smalldesktop') {
    font-size: 55px;
  }

  @include media('>=smalldesktop', '<desktop') {
    font-size: 70px;
  }

  @include media(">=desktop", '<largedesktop') {
    font-size: 70px;
  }

  @include media('>=largedesktop') {
    font-size: 80px;
  }
}

.home-header-description {
  @include media("<=phone") {
    font-size: 18px;
  }

  @include media(">=phone", '<tablet') {
    font-size: 18px;
  }

  @include media('>=tablet', '<smalldesktop') {
    font-size: 18px;
  }

  @include media('>=smalldesktop', '<desktop') {
    font-size: 18px;
  }

  @include media(">=desktop", '<largedesktop') {
    font-size: 20px;
  }

  @include media('>=largedesktop') {
    font-size: 24px;
  }
}

.home-header-img {
  margin-left: 20px;
  margin-top: 10px;
  display: block;
  visibility: visible;

  @include media("<=phone") {
    width: 80%
  }

  @include media(">=phone", '<tablet') {
    width: 400px;
  }

  @include media('>=tablet', '<smalldesktop') {
    width: 350px;
  }

  @include media('>=smalldesktop', '<desktop') {
    width: 400px;
  }

  @include media(">=desktop", '<largedesktop') {
    width: 450px;
  }

  @include media('>=largedesktop') {
    width: 520px;
  }
}

.home-header-story {
  @include media(">=phone") {
    display: none;
  }
}

.home-who-are-section-image{
  @include media("<=phone") {
    display: none;
  }
}

.home-3d-model {
  @include media("<=phone") {
    display: none;
  }
}

// Home header styles - End

// Home Who Are We styles - Start
.who-we-are-section {
  .header {
    font-family: "Product Sans Medium";
    font-size: 40px;
  }

  .description {
    @include media("<=phone") {
      font-size: 18px;
    }
  
    @include media(">=phone", '<tablet') {
      font-size: 18px;
    }
  
    @include media('>=tablet', '<smalldesktop') {
      font-size: 18px;
    }
  
    @include media('>=smalldesktop', '<desktop') {
      font-size: 18px;
    }
  
    @include media(">=desktop", '<largedesktop') {
      font-size: 20px;
    }
  
    @include media('>=largedesktop') {
      font-size: 22px;
    }
  }

  .image {
    @include media("<=phone") {
      width: 80%;
    }

    @include media(">=phone", '<tablet') {
      width: 300px;
    }

    @include media('>=tablet', '<smalldesktop') {
      width: 320px;
    }

    @include media('>=smalldesktop', '<desktop') {
      width: 330px;
    }

    @include media(">=desktop", '<largedesktop') {
      width: 400px;
    }

    @include media('>=largedesktop') {
      width: 400px;
    }
  }
}
// Home Who Are We styles - End
